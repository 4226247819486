import {
    useColorModeValue,
    Spacer,
    Heading,
    Button,
    Stack,
    Spinner,
    Tag,
    Code,
    Box,
    Text,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel
    } from "@chakra-ui/react";

import {useState} from 'react';

import RawTable from '../RawTable';
import ExportButton from '../ExportButton';

import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import xml from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import css from 'react-syntax-highlighter/dist/esm/languages/hljs/css';
import atomOneLight from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-light';
import atomOneDark from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-dark';

SyntaxHighlighter.registerLanguage('xml',xml);
SyntaxHighlighter.registerLanguage('js',js);
SyntaxHighlighter.registerLanguage('json',json);
SyntaxHighlighter.registerLanguage('css',css);

function getMime(inputText) {
    let mime = inputText.split(";")[0].split("/")[1];
    let syntaxLang = "";
    switch (mime){
        case "html":
            syntaxLang = "xml"; 
            break;
        case "javascript":
            syntaxLang = "js";
            break;
        case "json":
            syntaxLang = "json";
            break;
        case "css":
            syntaxLang = "css";
            break;
        default:
            syntaxLang = "text";
            mime = "text";
    }
    
    return {"show": mime, "parse":syntaxLang};

}

const SyntaxBox = (props) => {
  const [langData, setLangData] = useState({"show":"text", "parse":"text"})
  const isTruncated = props.sourceData['truncated']
  const sourceCode = props.sourceData['data']

  if (sourceCode && props.codeLang) {
    return (
        <>
            <Button m={3} onClick={() => setLangData(getMime(props.codeLang))}> Add Syntax Highlights </Button>
            <Text> Highlighted as: <Code>{langData['show']}</Code></Text>
            <Text>Content Type: <Code>{props.codeLang}</Code></Text>
            { isTruncated ? 
                <Box p={3}>
                <Tag size="lg" colorScheme="red">This data has been truncated due to size</Tag>
                </Box>
                :
                ""

            }
            <SyntaxHighlighter
                wrapLongLines
                language={langData['parse']}
                style={props.theme}
            >
              {atob(sourceCode).trim()}
            </SyntaxHighlighter>
        </>
      );
    }else {
        return (
            <Text> No content. </Text>
        )
    }
};

const prepareDomainData = (urls) => {
  const domainCounts = {};

  urls.forEach((item) => {
    try {
      const domainOrIP = new URL(item.url).hostname;

      // Validate and increment domain count
      if (domainOrIP) {
        domainCounts[domainOrIP] = (domainCounts[domainOrIP] || 0) + 1;
      }
    } catch (error) {
      console.error("Invalid URL skipped:", item.url, error);
    }
  });

  // Convert domainCounts to an array, sort by count, and then map to desired format
  return Object.entries(domainCounts)
    .sort((a, b) => b[1] - a[1]) // Sort by count in descending order
    .map(([domain, count]) => ({
      count, 
      domain, 
    }));
};

export default function DetailBox(props) {
    const codeStyle = useColorModeValue(atomOneLight, atomOneDark) 


   const consoleMsgData = () => {
	if(props.consoleMessages === " "){
		return (
		<>
                    <Stack p={5} direction="row">
                    <Text> Loading </Text>
                    <Spinner size="lg" />
                    </Stack>
                </>
		)
	} else if (props.consoleMessages.length > 0) {
		return (
			<SyntaxHighlighter style={codeStyle} language="text" showLineNumbers>
				{props.consoleMessages.join("\n")}
			</SyntaxHighlighter>
		)
	} else {
		return <Text> No console messages </Text>
	}


   }
	const requestedUrlData = () => {

	  const domainData = prepareDomainData(props.requestedUrls);

	  if(props.requestedUrls[0] === "Loading") {
	    return (
	      <Stack p={5} direction="row">
	        <Text>Loading</Text>
	        <Spinner size="lg" />
	      </Stack>
	    );
	  } else if (props.requestedUrls.length > 0) {
	    return  (
		    <>
  <Stack spacing={1} my="4">
    <Heading size="md">Third Party Domains</Heading>
    <Stack direction="row" align="center" justify="space-between">
      <ExportButton data={domainData} />
      <Spacer /> {/* This is optional since the button is already aligned to the left */}
    </Stack>
  </Stack>
  <RawTable data={domainData} />

  <Stack spacing={1} my="4">
    <Heading size="md">Requested Resources</Heading>
    <Stack direction="row" align="center" justify="space-between">
      <ExportButton data={props.requestedUrls} />
      <Spacer /> {/* This is optional since the button is already aligned to the left */}
    </Stack>
  </Stack>
  <RawTable data={props.requestedUrls} />
</>

			)
	  } else {
	    return <Text>No additional requests</Text>;
	  }
	}
    return (

        <Tabs minHeight="300px" variant="enclosed-colored">
          <TabList>
            <Tab>
                Source
            </Tab>
            <Tab>
                Resources 
            </Tab>
            <Tab>
                Console 
            </Tab>
                
          </TabList>
        
          <TabPanels>
            <TabPanel>
                <SyntaxBox codeLang={props.contentType} theme={codeStyle} sourceData={props.sourceData} />
            </TabPanel>
            <TabPanel>
                { requestedUrlData() }
            </TabPanel>
	    <TabPanel>
	    	{consoleMsgData() }
	    </TabPanel>
            </TabPanels>
        </Tabs>
    )


}

